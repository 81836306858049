import React from 'react'
import Header from '../components/Header/Header'
import AboutUs from '../components/AboutUs/AboutUs'
function Home() {
  return (
    <div>
        <Header/>
        {/* <AboutUs/> */}
    </div>
  )
}

export default Home